import { __awaiter, __generator } from "tslib";
import { defineComponent } from "vue";
import teamStore from "../../store/teamStore";
import userStore from "../../store/userStore";
export default defineComponent({
    props: {
        teams: {
            type: Array,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        hasEditAccess: {
            type: Boolean,
            required: true
        }
    },
    setup: function (props) {
        var _this = this;
        var disconnectUserFromTeam = teamStore().disconnectUserFromTeam;
        var disconnectTeamFromUser = userStore().disconnectTeamFromUser;
        var leaveTeam = function (team) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!confirm('Sikker på at du vil forlate tippelaget "' + team.name + '"?')) return [3 /*break*/, 3];
                        return [4 /*yield*/, disconnectUserFromTeam(team, props.user.id)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, disconnectTeamFromUser(props.user, team.id)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        return { leaveTeam: leaveTeam };
    }
});
