import { defineComponent, ref } from "vue";
import ProfileImage from "./ProfileImage.vue";
import ProfileDetails from "./ProfileDetails.vue";
import ProfileTeams from "./ProfileTeams.vue";
import userStore from "../../store/userStore";
import teamStore from "../../store/teamStore";
export default defineComponent({
    props: {
        uid: {
            type: String,
            required: true,
        },
    },
    setup: function (props) {
        var getUser = userStore().getUser;
        var user = ref();
        getUser(props.uid).then(function (x) {
            user.value = x;
        });
        var getTeams = teamStore().getTeams;
        var teams = ref();
        getTeams(props.uid).then(function (x) {
            teams.value = x;
        });
        return { user: user, teams: teams };
    },
    components: {
        ProfileImage: ProfileImage,
        ProfileDetails: ProfileDetails,
        ProfileTeams: ProfileTeams,
    },
});
