import { defineComponent } from "vue";
export default defineComponent({
    props: {
        user: {
            type: Object,
            required: true
        },
        hasEditAccess: {
            type: Boolean,
            required: true
        }
    }
});
